import { useState } from "react";
import AHLYBANK from "../../assets/images/ahlybank.png";
import BUYNOW from "../../assets/images/buy-now.png";
import CIBWALLET from "../../assets/images/cibwallet.png";
import CODE from "../../assets/images/code-purchase.webp";
import ETISALAT from "../../assets/images/etisalat.png";
import FAWRY from "../../assets/images/fawry.svg";
import ORANGECASH from "../../assets/images/orange-cash.png";
import VODAFONE_CASH from "../../assets/images/vodafonechash.png";
import { LessonDataI } from "../quiz/typesAndEnums";
import CodePurchase from "./purchaseMethods/CodePurchase";
import FawryPurchase from "./purchaseMethods/FawryPurchase";
import FreePurchase from "./purchaseMethods/FreePurchase";
import MobileWalletPurchase from "./purchaseMethods/MobileWalletPurchase";
import WalletPurchase from "./purchaseMethods/WalletPurchase";

export enum PurchaseMethods {
  CODE = "code",
  WALLET = "wallet",
  FAWRY = "fawry",
  MOBILE_WALLET = "mobileWallet",
}
const Purchase = ({
  lessonData,
  isMobile,
  isRepurchase = false,
}: {
  lessonData: LessonDataI;
  isMobile: boolean;
  isRepurchase?: boolean;
}) => {
  const [purchaseBlock, setPurchaseBlock] = useState(null);
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);

  if (lessonData.price === 0) return <FreePurchase lessonData={lessonData} />;

  // useEffect(() => {
  //   document.addEventListener("click", () => {
  //     setPaymentMethodsOpen(false);
  //   });

  //   return () => {
  //     document.removeEventListener("click", () => {
  //       setPaymentMethodsOpen(false);
  //     });
  //   };
  // }, []);

  return (
    <div className="purchase-lesson">
      {!isRepurchase && (
        <div className="d-flex">
          <img className="m-auto w-50" src={BUYNOW} alt="اشتري الان" />
        </div>
      )}
      <div className="d-flex my-3">
        <div className="btn-group m-auto w-100 mb-4">
          <div className="m-auto rounded-ea border-ea px-3 py-2 payment-methods-container">
            <p
              className="choices-title"
              onClick={() => {
                setPaymentMethodsOpen(!paymentMethodsOpen);
                if (paymentMethodsOpen) setPurchaseBlock(null);
              }}
            >
              اختر وسيلة الدفع
            </p>
            {paymentMethodsOpen && (
              <>
                <div className="border-bottom my-3"></div>
                {lessonData.code && (
                  <p
                    className={
                      purchaseBlock === PurchaseMethods.CODE ? "active" : ""
                    }
                    onClick={() => setPurchaseBlock(PurchaseMethods.CODE)}
                  >
                    كود
                    <img className="code" src={CODE} alt="كود" />
                  </p>
                )}
                {lessonData.wallet && (
                  <p
                    className={
                      purchaseBlock === PurchaseMethods.WALLET ? "active" : ""
                    }
                    onClick={() => setPurchaseBlock(PurchaseMethods.WALLET)}
                  >
                    رصيد المحفظة
                  </p>
                )}
                {lessonData.fawry && (
                  <p
                    className={
                      purchaseBlock === PurchaseMethods.FAWRY ? "active" : ""
                    }
                    onClick={() => setPurchaseBlock(PurchaseMethods.FAWRY)}
                  >
                    فوري
                    <img className="fawry" src={FAWRY} alt="فوري" />
                  </p>
                )}
                {lessonData.vodafone_cash && (
                  <p
                    className={`wallets-container ${
                      purchaseBlock === PurchaseMethods.MOBILE_WALLET ? "active" : ""
                    }
                    `}
                    onClick={() =>
                      setPurchaseBlock(PurchaseMethods.MOBILE_WALLET)
                    }
                  >
                    محفظة إلكترونية
                    <ul>
                      <li>
                        <img
                          className="mobile-wallet"
                          src={VODAFONE_CASH}
                          alt="فودافون كاش"
                        />
                        <img
                          className="mobile-wallet"
                          src={ETISALAT}
                          alt="اتصالات"
                        />
                        <img
                          className="mobile-wallet"
                          src={ORANGECASH}
                          alt="اتصالات"
                        />
                        <img
                          className="mobile-wallet"
                          src={AHLYBANK}
                          alt="اتصالات"
                        />
                        <img
                          className="mobile-wallet"
                          src={CIBWALLET}
                          alt="اتصالات"
                        />
                      </li>
                    </ul>
                  </p>
                )}
              </>
            )}
          </div>
          {/* <select
            className="m-auto rounded-ea purchase-options"
            onChange={(e) => setPurchaseBlock(e.target.value)}
          >
            <option selected>اختر وسيلة الدفع</option>
            {lessonData.code && (
              <option value={PurchaseMethods.CODE}>كود</option>
            )}
            {lessonData.wallet && (
              <option value={PurchaseMethods.WALLET}>رصيد المحفظة</option>
            )}
            {lessonData.fawry && (
              <option value={PurchaseMethods.FAWRY}>
                <img src={FAWRY} alt="فوري" />
                فوري
              </option>
            )}
            {lessonData.vodafone_cash && (
              <option value={PurchaseMethods.MOBILE_WALLET}>
                محفظة إلكترونية
              </option>
            )}
          </select> */}
        </div>
      </div>
      <div className="purchase-block w-100">
        {purchaseBlock === PurchaseMethods.CODE && (
          <CodePurchase lessonData={lessonData} isMobile={isMobile} />
        )}
        {purchaseBlock === PurchaseMethods.WALLET && (
          <WalletPurchase lessonData={lessonData} isMobile={isMobile} />
        )}
        {purchaseBlock === PurchaseMethods.FAWRY && (
          <FawryPurchase lessonData={lessonData} isMobile={isMobile} />
        )}
        {purchaseBlock === PurchaseMethods.MOBILE_WALLET && (
          <MobileWalletPurchase lessonData={lessonData} />
        )}
      </div>
    </div>
  );
};

export default Purchase;
