import { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { endpoints } from "../../../hooks/endpoints";
import { getToken } from "../../../hooks/utils";

const MobileWalletPurchase = ({ lessonData }) => {
  const mobileRef = useRef(null);
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const handlePurchase = () => {
    setIsPending(true);
    fetch(endpoints.fawaterakPurchase, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify({
        product_id: lessonData.lesson_id,
        payment_method: 4,
        mobile_number: mobileRef.current.value,
      }),
      // signal: abortCont.signal
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("this is result: ", result);
        setData(result);
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err);
        setError("حدث خطأ");
        setIsPending(false);
      });
  };

  return (
    <div className="d-block">
      <div className="mb-3">
        <div className="mb-3 mx-auto mobile-wallet-form">
          <label htmlFor="wallet-number" className="form-label">
            أدخل رقم المحفظة
          </label>
          <input
            ref={mobileRef}
            id="wallet-number"
            type="text"
            className="form-control"
            onChange={(e) => {
              if (!e.target.value) return;
              const regex = /^\d+$/;
              if (regex.test(e.target.value ?? "")) {
                mobileRef.current.value = e.target.value;
                setError(null);
              } else {
                mobileRef.current.value = "";
                setError("مسموح بإدخال الأرقام فقط");
              }
            }}
          />
        </div>
        {!!error && <p className="text-danger text-center">{error ?? ""}</p>}
        {!isPending && (
          <button
            className="rounded-ea btn ea-btn m-auto"
            onClick={handlePurchase}
          >
            إرسال طلب الدفع
          </button>
        )}
        {isPending && (
          <button
            className="rounded-ea btn ea-btn m-auto"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm mx-2"
              role="status"
              aria-hidden="true"
            ></span>
            إرسال طلب الدفع...
          </button>
        )}
        {data && (
          <div className="d-flex">
            <div
              className="rounded-ea mx-auto my-2 alert alert-success responsive-purchase-mobile-wallet"
              role="alert"
            >
              <p>
                سيقوم مزود خدمة المحفظة الإلكترونية الخاص بكم بإرسال رسالة إلى
                الرقم الذي أدخلته لإتمام عملية الدفع
              </p>
              <p>
                أو يمكنك الدفع عن طريق هذا ال QR كود من تطبيق المحفظة
                الإلكترونية
              </p>
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  padding: ".5rem",
                  backgroundColor: "#fff",
                  borderRadius: "1rem",
                }}
                className="m-1 qrcode"
                value={data.meezaQrCode}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileWalletPurchase;
