import { useEffect, useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import { endpoints } from "../../hooks/endpoints";
import usePWA from "../../hooks/usePWA";
import { getToken } from "../../hooks/utils";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  currentTeacherData,
  setPortal,
  setUserProfileData,
  userData,
} from "../../store/slices/storeSlice";
import MobileNavbar from "./mobile-nav/MobileNavbar";
import NavbarItems from "./NavbarItems";

const Navbar = () => {
  const { user } = useAppSelector((state) => state.store);
  const [mainPage, setMainPage] = useState("/");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAppInstalled } = usePWA();

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem(`${window.location.hostname}_user`)
    );
    if (
      userData &&
      userData?.data_completed !== "1" &&
      !["teacher", "admin"].includes(userData?.role ?? "")
    )
      navigate("/complete-info");
  }, [window.location.pathname]);

  useEffect(() => {
    fetch(endpoints.me, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.uid) {
          dispatch(userData(res));
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem(`${window.location.hostname}_user`);
        navigate("/");
      });
  }, []);

  useEffect(() => {
    if (user) {
      // checkIfTeacher();
      setMainPage("/school-grades");
    } else {
      setMainPage("/");
    }
  }, [user]);

  const { portalRefetch, userRefetch, portal } = useAppSelector(
    (state) => state.store
  );

  useEffect(() => {
    fetch(
      endpoints.getPortal +
        `?portal=${
          window.location.hostname !== "localhost"
            ? window.location.hostname
            : // : "localhost"
              "dev-eliteacademy.web.app"
        }`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setPortal(result));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [portalRefetch]);

  useEffect(() => {
    if (user) {
      fetch(endpoints.getUser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(setUserProfileData(result));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRefetch, user]);

  const userProfileData = useAppSelector(
    (state) => state.store.userProfileData
  );

  useEffect(() => {
    console.log("current portal after update", portal);

    if (portal) {
      try {
        // update teacher data
        dispatch(currentTeacherData(portal?.category));
        const logo = document.getElementById("logo-webp") as HTMLImageElement;
        logo.src = portal?.logoUrl;
        const favicon = document.getElementById(
          "teacher-favicon"
        ) as HTMLLinkElement;
        favicon.href = portal?.favicon;
        document.title = portal?.name;
      } catch (error) {
        console.error("Error fetching manifest:", error);
      }
    }
  }, [portal]);

  useEffect(() => {
    const notificationAudio = new Audio("/notification.mp3");
    let userinteraction = false;
    document.addEventListener("click", () => {
      if (userinteraction) return;
      userinteraction = true;
      notificationAudio.volume = 1;
    });
  }, []);

  return (
    <>
      {isAppInstalled ? (
        <>
          <nav className="navbar navbar-expand-lg navbar-light shadow-sm bg-light bg-gradient">
            <Link to={mainPage} className="p-0 m-0">
              <img id="logo-webp" className="mx-4" src="" alt="" height="50" />
            </Link>
          </nav>
          <MobileNavbar mainPage={mainPage} user={user} />
        </>
      ) : (
        <nav className="navbar navbar-expand-lg navbar-light shadow-sm bg-light bg-gradient">
          <div className="container-fluid">
            {user && (
              <button
                className="navbar-toggler bg-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
            )}
            <Link to={mainPage} className="p-0 m-0">
              <img id="logo-webp" className="mx-4" src="" alt="" height="50" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <NavbarItems mainPage={mainPage} />
            </div>
            <a href="/install" className="btn ea-btn rounded-ea">
              حمل التطبيق
            </a>
          </div>
        </nav>
      )}
    </>
  );
};

export default Navbar;
